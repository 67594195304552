.allActivityNoDataList {
  background-color: #fff;
  padding: "16px 24px";
}
.allActivityList {
  display: "block";
  background-color: #fff;
  border-radius: 8px;
  padding: "16px 16px";
  margin: "10px";
  border: "#FFF";
}
button.Mui-disabled:disabled {
  background: #cdcdcd;
  color: #fff;
}
