.carousalmainContainer {
  position: relative;
}
.carousalmainContainer div {
  position: absolute;
  bottom: 20px;
  left: 10px;
  color: #fff;
  max-width: 210px;
  text-align: left;
}

.innerimagesContent {
  position: absolute;
  bottom: 10px;
  color: #000;
  left: 50px;
}
.caurousalSlider {
  height: auto;
  overflow: hidden;
}

