.icon_bg {
  background: #ffffff;
  box-shadow: 0px 16px 31px rgba(145, 96, 209, 0.28);
  border-radius: 18px;
  float: left;
}
.icon_bg_hover {
  background: none;
  box-shadow: none;
  border-radius: none;
  float: left;
}
